import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledFooter = styled.footer`
${tw`flex justify-between bg-white px-3 sm:px-8 py-1`}

& div {
  ${tw`flex flex-col`}
}
& div a {
  ${tw`text-sm sm:text-base`}
  font-family: 'Futura Medium';
}
`;

const Footer = ({ locale = 'en-us' }) => {
  const lang = locale.substring(0, 2)
  return (
    <StyledFooter>
      <div>
        <Link to={`/${lang}/contact`} aria-label='Contact'>Contact</Link>
        <Link to={`/${lang}/newsletter`} aria-label='Newsletter'>Newsletter</Link>
      </div>
      <div>
        <Link to={`/${lang}/dealers`} aria-label='Retailers'>Retailers</Link>
        <Link to={`/${lang}/dealers`} aria-label='Resellers'>Resellers</Link>
      </div>
      <div>
        <a href='https://www.instagram.com/poderegrassellino/' aria-label='Instagram' target='_blank' rel="noreferrer">
          Instagram
        </a>
        <Link
          to='/'
          aria-label='Facebook'
        >Facebook</Link>
      </div>
    </StyledFooter>
  )
}

export default Footer

export const query = graphql`
query FooterQuery($lang: String) {
  prismic {
    allFooters(lang: $lang) {
      edges {
        node {
          _meta {
            lang
            type
            uid
            alternateLanguages {
              lang
              type
              uid
            }
          }
          links {
            label
            link {
              ... on PRISMIC_Newsletter {
                _linkType
                _meta {
                  lang
                  type
                  uid
                  alternateLanguages {
                    lang
                    type
                    uid
                  }
                }
              }
              ... on PRISMIC_Contact_page {
                _linkType
                _meta {
                  lang
                  type
                  uid
                  alternateLanguages {
                    lang
                    type
                    uid
                  }
                }
              }
              ... on PRISMIC_Dealers_page {
                title
                _linkType
                _meta {
                  lang
                  type
                  uid
                  alternateLanguages {
                    lang
                    type
                    uid
                  }
                }
              }
              ... on PRISMIC__ExternalLink {
                target
                _linkType
                url
              }
            }
          }
        }
      }
    }
  }
}

`;

