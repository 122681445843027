import 'tailwindcss/dist/base.css'
import Layout from './src/components/Layout'
import React from 'react';
import { registerLinkResolver } from '@prismicio/gatsby-source-prismic-graphql'
import linkResolver from './src/utils/linkResolver'


export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
};


registerLinkResolver(linkResolver)
