import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const StyledLogo = styled.img`
    ${tw`px-3 sm:px-20 h-24 block`}
    height: ${props => props.isPrimary ? '5rem' : '4rem'};
`;

const HeaderLogo = ({ isPrimary, logo }) => (
    <StyledLogo
        alt='Famiglia Santoni Logo'
        isPrimary={isPrimary}
        src={logo}
    />
)



export default HeaderLogo
