import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Link } from 'gatsby'

import HeaderLogo from './HeaderLogo'
import Hamburger from '../Hamburger'

import { Logo_Secondary, Famiglia_Logo_Primary } from '../../images'
import { useLocation } from '@reach/router'
import { LocaleContext } from '../../context/locale'

const StyledHeader = styled.header`
   ${tw`bg-white h-24 mt-6 
        sm:h-28 
        sm:mt-10 flex 
        flex-col justify-center 
        items-center
        `}
`;

const INITIAL_STATE = {
    logo: Famiglia_Logo_Primary,
    isPrimary: true,
}

const Header = ({ toggleMenu, isMenuOpen }) => {
    const location = useLocation()
    const [state, setState] = useState(INITIAL_STATE)
    const locale = useContext(LocaleContext)

    useEffect(() => {
       
        let path = location.pathname
        let logo = Famiglia_Logo_Primary

        switch (path) {
            case `/${locale.activeLocale}/wine`:
                logo = Logo_Secondary
                break;
            case `/${locale.activeLocale}/wine/amandi`:
                logo = Logo_Secondary
                break;
            case `/${locale.activeLocale}/wine/modis`:
                logo = Logo_Secondary
                break;
            case `/${locale.activeLocale}/olive-oil`:
                logo = Logo_Secondary
                break;
            default:
                logo = Famiglia_Logo_Primary
        }
        setState({
            ...state,
            logo: logo,
            isPrimary: true,
        })

    }, [location.pathname, locale.activeLocale])

    return (
        <StyledHeader>
            <Hamburger
                toggleMenu={() => toggleMenu()}
                isMenuOpen={isMenuOpen}
            />
            <Link to='/' aria-label='Homepage'>
                <HeaderLogo
                    isPrimary={state.isPrimary}
                    logo={state.logo}
                />
            </Link>
        </StyledHeader>
    )
}


export default Header
