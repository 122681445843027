import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import GlobalStyle from '../../globalStyle'
import Header from '../Header'
import Footer from '../Footer'
import Menu from '../Hamburger/Menu'
import LocaleProvider from '../../context/locale'
import SEO from '../../components/SEO/seo'
const MainContentWrapper = styled.main`
    min-height: calc(100vh - 270px);
    margin-top: 4rem;
`;

const Layout = ({ location, children, pageContext: { lang } }) => {
    const [isMenuOpen, setMenu] = useState(false)
    const toggleMenu = () => setMenu(!isMenuOpen)

    return (
        <>
            <GlobalStyle />
            <SEO title='Famiglia Santoni' />
            <LocaleProvider locale={lang} location={location}>
                <Header
                    toggleMenu={toggleMenu}
                    isMenuOpen={isMenuOpen}
                    locale={lang}
                />
                <Menu
                    toggleMenu={toggleMenu}
                    isMenuOpen={isMenuOpen}
                />
                <MainContentWrapper>
                    {children}
                </MainContentWrapper>

                <Footer locale={lang} />
            </LocaleProvider>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
