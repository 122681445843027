import { createGlobalStyle } from 'styled-components';

import FuturaLightTTF from './assets/font/Futura-Light.ttf'
import FuturaMediumTTF from './assets/font/Futura-Medium.ttf'
import FuturaBoldTTF from './assets/font/Futura-Bold.ttf'
import FuturaLightWOFF from './assets/font/Futura-Light.woff'
import FuturaMediumWOFF from './assets/font/Futura-Medium.woff'
import FuturaBoldWOFF from './assets/font/Futura-Bold.woff'

const GlobalStyle = createGlobalStyle`

:root {
  --font-color_primary: #12100B; 

}
@font-face {
  font-family: 'Futura Light';
  src: local('Futura Light'), local('FuturaLight'),
  url(${FuturaLightWOFF}) format('woff'),
  url(${FuturaLightTTF}) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Futura Medium';
  src: local('Futura Medium'), local('FuturaMedium'),
  url(${FuturaMediumWOFF}) format('woff'),
  url(${FuturaMediumTTF}) format('truetype');
  font-weight: medium;
}

@font-face {
  font-family: 'Futura Bold';
  src: local('Futura Bold'), local('FuturaBold'),
  url(${FuturaBoldWOFF}) format('woff'),
  url(${FuturaBoldTTF}) format('truetype');
  font-weight: bold; 
}

html {
  scroll-behavior: smooth;
  color: var(--font-color_primary);
}

body  {
  width: 100%;
}

& p {
  font-family: 'Futura Medium';
}


* {
   font-family: 'Futura Light', 'Futura Medium, Futura Bold';
   margin: 0;
   padding: 0; 
   box-sizing: border-box;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

`;

export default GlobalStyle