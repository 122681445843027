import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro';

import MenuOpen from '../../images/Menu_Open.svg'
import MenuClose from '../../images/Menu_Close.svg'

const StyledHamburger = styled.div`
${tw`absolute left-0 top-0 sm:mt-16 p-3 sm:ml-2 md:ml-8 lg:ml-16 z-10`}
`;


const Icon = (MenuLogo) => <img src={MenuLogo} alt=''></img>

const Hamburger = ({ isMenuOpen, toggleMenu }) =>
  <StyledHamburger>
    <button
      onClick={toggleMenu}
      aria-label={isMenuOpen ? 'Close' : 'Open'}
    >
      {isMenuOpen ?
        Icon(MenuClose) :
        Icon(MenuOpen)}
    </button>
  </StyledHamburger>

export default Hamburger