import React, { useContext } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'gatsby'
import Hamburger from '../Hamburger'
import { LocaleContext } from '../../context/locale'

import LanguageSwitcher from '../LanguageSwitcher'

const Nav = styled.nav`
${tw`flex flex-col mb-10`};

& a {
  ${tw`text-center text-2xl p-2`};
  font-family: 'Futura Medium';
}
`;

const Overlay = styled.div`
${tw`w-full bg-white fixed bottom-0 flex items-center justify-center transition duration-500 ease-in-out`};
  z-index: 1000;
  height: 100%;

  &.hidden {
    transform: translate(0, 100vh);
  }
`;

const links = [
    {
        url: '/wine',
        label: {
            en: 'Wines',
            it: 'Vino'
        }
    },
    {
        url: '/olive-oil',
        label: {
            en: 'Olive Oil',
            it: "Olio",
        }
    },
    {
        url: '/about',
        label: {
            en: 'About Santoni',
            it: 'Storia',
        }
    },
    {
        url: '/contact',
        label: {
            en: 'Contact',
            it: 'Contatti',
        }
    },
]

const MenuLinks = ({ links, toggleMenu }) => {
    const { activeLocale } = useContext(LocaleContext)
    return (
        <Nav>
            {links.map((link, i) =>
                <Link
                    aria-label={link.label[activeLocale]}
                    key={i}
                    to={`/${activeLocale}${link.url}`}
                    onClick={toggleMenu}
                >
                    {link.label[activeLocale]}
                </Link>
            )}
            <LanguageSwitcher />
        </Nav>
    )
}

const Menu = ({ isMenuOpen, toggleMenu }) => {
    return (
        <Overlay className={isMenuOpen ? '' : 'hidden'}>
            <Hamburger
                toggleMenu={toggleMenu}
                isMenuOpen={isMenuOpen}
            />
            <MenuLinks
                links={links}
                toggleMenu={toggleMenu}
            />
        </Overlay>
    )
}

export default Menu
