module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"web-famigilia-santoni","short_name":"famiglia-santoni","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b6a24d398cf23e865953a1ee77e2b9c7"},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"famiglia-santoni","defaultLang":"en-us","langs":["en-us","it-it"],"shortenUrlLangs":true,"pages":[{"type":"Wine","match":"/:lang/wine/:uid/","component":"/opt/build/repo/src/templates/wine.js"},{"type":"Homepage_","match":"/:lang","component":"/opt/build/repo/src/templates/homepage.js"},{"type":"Newsletter","match":"/:lang/newsletter","component":"/opt/build/repo/src/templates/newsletter.js"},{"type":"Olive Oil","match":"/:lang/:uid","component":"/opt/build/repo/src/templates/oliveoil.js"},{"type":"Wines Page","match":"/:lang/wine","component":"/opt/build/repo/src/templates/allWines.js"},{"type":"About Page","match":"/:lang/about","component":"/opt/build/repo/src/templates/about.js"},{"type":"Dealers Page","match":"/:lang/dealers","component":"/opt/build/repo/src/templates/dealers.js"},{"type":"Contact Page","match":"/:lang/contact","component":"/opt/build/repo/src/templates/contact.js"}],"path":"/preview","previews":false,"omitPrismicScript":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
