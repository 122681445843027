// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-all-wines-js": () => import("./../../../src/templates/allWines.js" /* webpackChunkName: "component---src-templates-all-wines-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-dealers-js": () => import("./../../../src/templates/dealers.js" /* webpackChunkName: "component---src-templates-dealers-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-newsletter-js": () => import("./../../../src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-oliveoil-js": () => import("./../../../src/templates/oliveoil.js" /* webpackChunkName: "component---src-templates-oliveoil-js" */),
  "component---src-templates-wine-js": () => import("./../../../src/templates/wine.js" /* webpackChunkName: "component---src-templates-wine-js" */)
}

