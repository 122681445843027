import React, { useContext } from 'react'
import styled from 'styled-components'
import locales from '../../../config/locales'
import { LocaleContext } from '../../context/locale'
import tw from 'twin.macro'

const LanguageSwitcher = () => {
    const { activeLocale, updateLocale } = useContext(LocaleContext)
    if (!activeLocale) return null;

    return (
        <Wrapper>
            {locales.map(({ label, path }, index) => (
                <React.Fragment key={index}>
                    <input
                        type='radio'
                        id={path}
                        name='language selector'
                        value={path}
                        onChange={event => updateLocale(event.target.value)}
                    >
                    </input>
                    <label htmlFor={path}>
                        {path.toUpperCase()}
                    </label>
                </React.Fragment>
            ))}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    flex-direction: row;

    & div {
        
    }
    & input {
        ${tw`-ml-3 sm:ml-0`}
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
    }

    & input:checked + label {
        text-decoration: underline;
    }

    & label {
        text-align: center;
        font-family: 'Futura Medium';
        cursor: pointer;
        margin: 0.5rem;
    }

`;

export default LanguageSwitcher