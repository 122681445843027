
import { defaultLanguage } from '../../prismic.config'

export function linkResolver(doc) {
  doc.lang = doc.lang.slice(0, 2)

  if (doc.type === 'homepage_') {
    return doc.lang === defaultLanguage
      ?
      `/`
      :
      `/${doc.lang}`
  }

  if (doc.type === 'newsletter') {
    return doc.lang === defaultLanguage
      ?
      `/${doc.lang}/${doc.uid}`
      :
      `/${doc.lang}/${doc.uid}`
  }

  if (doc.type === 'wines_page') {
    return doc.lang === defaultLanguage
      ?
      `/${doc.lang}/wine`
      :
      `/${doc.lang}/wine`
  }

  if (doc.type === 'wine') {
    return doc.lang === defaultLanguage
      ?
      `/${doc.lang}/${doc.type}/${doc.uid}`
      :
      `/${doc.lang}/${doc.type}/${doc.uid}`
  }

  if (doc.type === 'olive_oil') {
    return doc.lang === defaultLanguage
      ?
      `/${doc.lang}/${doc.uid}`
      :
      `/${doc.lang}/${doc.uid}`
  }

  if (doc.type === 'about_page') {
    return doc.lang === defaultLanguage
      ?
      `/${doc.lang}/${doc.uid}`
      :
      `/${doc.lang}/${doc.uid}`
  }

  return '/';
}