module.exports = [
  {
    default: true,
    path: `en`,
    label: `English`,
    locale: `en-us`,
  },
  {
    path: `it`,
    label: `Italian`,
    locale: `it-it`,
  },
];